//Styling
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "../App.css"

//Context
import { useContext } from "react"
import AuthContext from "../context/AuthContext"

//Navigate
import {Link} from 'react-router-dom'

const LoginPopup = () => {
    let {loginUser, toggleLogin} = useContext(AuthContext)

    return(
        <Modal show={true} onHide={toggleLogin} style={{marginTop: "10vh"}}>
            <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{maxHeight: "60vh"}}>
                    <form onSubmit={loginUser}>
                        <div class="mb-3">
                            <label for="email" class="form-label">Email address</label>
                            <input id="email" class="form-control" type="email" name="email" placeholder="Email" />
                        </div>
                        <div class="mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input id="password" type="password" class="form-control" name="password" placeholder="Password" />
                        </div>
                        <input class="btn btn-primary" type="submit" value="Login" />
                    </form>
                    <Link to="/register" style={{width: "100%", textAlign: "center"}} onClick={toggleLogin}>Create an account</Link>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" class="btn btn-secondary" onClick={toggleLogin}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default LoginPopup