import { useEffect, useState } from 'react';

import "../App.css";

const ImageUpload = ({getImages}) => {

    const [files, setFiles] = useState([]);

    const [watermark, setWatermark] = useState("")

    // const onChange = (e) => {
    //     setWatermark(e.target.value)
    // }

    const handleDragOver = e => {
        e.preventDefault();
        document.getElementById("dropzone").style.backgroundColor = "rgba(219, 219, 219, 1)";
        document.getElementById("dropzone").style.color = "black";
    }

    const handleDragLeave = e => {
        e.preventDefault();
        document.getElementById("dropzone").style.backgroundColor = "#fff";
        document.getElementById("dropzone").style.color = "black";
    }

    const handleOnDrop = e => {
        //prevent the browser from opening the image
        e.preventDefault(); 
        e.stopPropagation(); 

        let updatedFiles = files

        // setFileList(e.dataTransfer.files);
        let maxOrder
        if (files.length > 0) {
            const orders = files.map(file => {return parseInt(file.order)})
            maxOrder = Math.max(...orders)
        }
        else {
            maxOrder = 0
        }

        for (let i=0; i < e.dataTransfer.files.length; i++) {
            const file = e.dataTransfer.files[i]
            const url = URL.createObjectURL(file)
            

            const updateObject = {
                id: parseInt(maxOrder ? maxOrder + (i+1) : i + 1),
                file: file,
                url: url,
                order: parseInt(maxOrder ? maxOrder + (i+1) : i + 1),
                color: 'black',
                position: 'br',
            }
            updatedFiles = [...updatedFiles, updateObject]
            setFiles(updatedFiles)
        }

        document.getElementById("dropzone").style.backgroundColor = "#fff";
        document.getElementById("dropzone").style.color = "black";
    }

    const onClick = e => {
        let updatedFiles = files
        if (updatedFiles.length > 0) {
            updatedFiles = updatedFiles.filter(file => parseInt(file.id) !== parseInt(e.target.id))
            setFiles(updatedFiles)
        }
    }

    useEffect(() => {
        if (files.length > 0) {
            let imageArray = []
            
            for (let i=0; i<files.length; i++) {
                let formData = new FormData()
                formData.append('image', files[i].file)
                if (watermark) {
                    formData.append('watermark', watermark)
                }
                formData.append('order', files[i].order)
                formData.append('color', files[i].color)
                formData.append('position', files[i].position)
                imageArray.push(formData)
            }
            getImages(imageArray)
        }
    }, [files, watermark])

    const [dragId, setDragId] = useState();

    const handleDrag = e => {
        setDragId(e.currentTarget.id);
    }

    const handleDrop = (e) => {
        const dragFile = files.find((file) => file.id == dragId);
        const dropFile = files.find((file) => file.id == e.currentTarget.id);
    
        const dragFileOrder = dragFile.order;
        const dropFileOrder = dropFile.order;
    
        const updatedFileOrder = files.map((file) => {
            if (file.id == dragId) {
                file.order = dropFileOrder;
            }
            if (file.id == e.target.id) {
                file.order = dragFileOrder;
            }
            return file;
        });
        setFiles(updatedFileOrder);
    }

    const onOpenPopup = (e) => {
        console.log(e.target.id)
        const editImage = files.find(file => file.id == e.target.id)
        // openPopup(editImage)
    }

    // useEffect(() => {
    //     if (newImage) {
    //         const oldImage = files.find(file => file.id == newImage.id)
    //         const oldIndex = files.indexOf(oldImage)
    //         files[oldIndex] = newImage
    //         console.log(newImage)
    //     } 
    // }, [newImagePing])

    return (
        <>
            <div className="imageUploadPreviewDiv">
                <div id="dropzone" className="dropDiv" onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleOnDrop}>
                    Drag and drop your images here
                </div>
                <div className="imagePreviewDiv">
                    { files
                        ? 
                            files
                            .sort((a, b) => a.order - b.order)
                            .map((file) => {
                                return(
                                    <>
                                    {file.id ? 
                                        <div 
                                            draggable={true} 
                                            onDragOver={(e) => e.preventDefault()} 
                                            onDragStart={handleDrag}
                                            onDrop={handleDrop}
                                            className="imageDiv"
                                            id={file.id}
                                        >
                                            {/* <span>{file.id + "Order:" + file.order}</span> */}
                                            <div onClick={onClick} id={file.id} className="deleteDiv">
                                                <svg id={file.id} onClick={onClick} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="rgb(242, 73, 51)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line id={file.id} x1="18" y1="6" x2="6" y2="18"></line><line id={file.id} x1="6" y1="6" x2="18" y2="18"></line></svg>
                                            </div>
                                            <img onClick={onOpenPopup} id={file.id} src={file.url} className="imagePreview" alt={`To be uploaded. Order + ${file.order}`} />
                                            
                                        </div>
                                    : null}
                                    </>
                                )
                            })
                        : null
                    }
                </div>
            </div>
            {/* <div className="watermarkDiv">
                <label htmlFor="watermark">Watermark Text</label>
                <input id="watermark" value={watermark ? watermark : ""} onChange={onChange}></input>
            </div> */}
        </>
    )
}

export default ImageUpload
