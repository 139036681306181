import { useState, useEffect, useCallback, useContext } from "react";
import AuthContext from "../context/AuthContext";
import {Helmet} from "react-helmet";
import {FacebookIcon, FacebookShareButton} from "react-share"

//Styling
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "../App.css"

//Components
import ImageDisplay from "./imageDisplay";
import Comment from "./comment";
import WriteComment from "./writeComment";

function ItemPopup({item_id, closePopup, getZoomCoords}) {
    const [item, setItem] = useState()
    const [refresh, setRefresh] = useState(true)

    let {domain} = useContext(AuthContext)

    useEffect(() => {
        fetch(`${domain}/api/item/${item_id}/`)
            .then(res => res.json())
            .then(data => {
                setItem(data)
                getZoomCoords(data.location.coordinates)
            })
            .catch((err) => {
                console.log(err)
                setItem(null)
                alert("This item does not exist!")
            })
    }, [item_id, refresh])

    const close = () => {
        setItem(null)
        closePopup()
    }

    const doRefresh = useCallback(() => {
        setRefresh(!refresh)
    })

    return(
        <>
            {item ?
                <Helmet>
                    <meta name="description" content={item.description} />
                    {item.files.length > 0 ?
                        <meta name="og:image" content={item.files[0].image} />
                        : null
                    }
                </Helmet>
            : null}
            {item ?
                <Modal show={true} onHide={close} dialogClassName="modal-90w">
                    <Modal.Header closeButton>
                        <Modal.Title>Item</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{maxHeight: "80vh", overflowY: "scroll"}}>
                        <ImageDisplay item={item} inPopup={true} />
                        <div>
                            <h5>Description:</h5>
                            <p>{item.description}</p>
                        </div>
                        {/* <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <td>Type:</td>
                                    <td>{building.building_category.name}</td>
                                </tr>
                            </tbody>
                        </Table> */}
                        <h5>Share:</h5>
                        <FacebookShareButton url={`${domain}/?item=${item.id}`} quote={item.description} className="share">
                            <FacebookIcon height={20} width={20} />
                        </FacebookShareButton>
                        <h5>Comments:</h5>
                        {item.comments ?
                            item.comments.map(comment => {
                                return(
                                    <Comment comment={comment} indent={0} doRefresh={doRefresh} />
                                )
                            })
                        : null}
                        <WriteComment itemId={item.id} replyTo={null} doRefresh={doRefresh} />
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            : null}
        </>
    )
}

export default ItemPopup