import { useEffect, useState } from 'react'

import WriteComment from './writeComment'
import UserIcon from './userIcon'

import Card from 'react-bootstrap/Card'

function Comment({comment, indent, doRefresh}) {
    return(
        <div id={'comment ' + comment.id.toString()} style={{position: 'relative', left: `${indent}%`, width: `${100-indent}%`}}>
            <Card>
                <Card.Body>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "flex-start"}}>
                        <UserIcon name={comment.author.first_name + " " + comment.author.last_name} profile={comment.author.profile} menu={false} />
                        <span>: {comment.text}</span>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <WriteComment itemId={null} replyTo={comment.id} doRefresh={doRefresh} />
                </Card.Footer>
            </Card>
            
            {comment.replies ? 
                comment.replies.map(reply => {
                    return(
                        <Comment comment={reply} indent={indent + 3} doRefresh={doRefresh} />
                    )
                })
            : null}
        </div>
    )
}

export default Comment