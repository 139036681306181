import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import "../App.css"

const Footer = () => {
    const year = new Date().getFullYear()

    return(
        <footer className="footer">
            <Row xs={1} sm={2} style={{marginRight: 0, marginLeft: 0}}>
                <Col className="d-flex flex-column align-items-center justify-content-center" style={{paddingRight: 0}}>
                    <span className="footer-font copyright-text pb-2">Website &copy; {year} <a href="https://www.digitalhistory.ca" target="_blank">Digital History Canada</a></span>
                </Col>
                <Col className="d-flex justify-content-center align-items-center" style={{color: "black", paddingRight: 0}}>
                    <a target="_blank" href="https://www.digitalhistory.ca/"><i className="bi bi-globe mx-3 social-logo"></i></a>
                    <a target="_blank" href="https://www.facebook.com/digitalhistorycanada/"><i className="bi bi-facebook mx-3 social-logo"></i></a>
                    <a target="_blank" href="https://www.instagram.com/digitalhistorycanada/"><i className="bi bi-instagram mx-3 social-logo"></i></a>
                    <a target="_blank" rel="noreferrer" href="mailto:digitalhistorycanada@gmail.com"><i className="bi bi-envelope mx-3 social-logo"></i></a>
                </Col>
            </Row>
        </footer>
    )
}

export default Footer;