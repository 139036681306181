import {Navigate, useNavigate} from 'react-router-dom'
import { useContext } from "react"
import AuthContext from "../context/AuthContext"
import { useParams } from 'react-router-dom'

const Activate = () => {
    let {uid, token} = useParams()
    
    let {user, domain} = useContext(AuthContext)
    const history = useNavigate()

    const activate = async () => {
        let response = await fetch(`${domain}/auth/users/activation/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'uid': uid,
                'token': token,
            })
        })
        // let data = await response.json()
        if (response.status === 204) {
            alert("Your account has been activated! You may now log into your account")
            history("/")
        } else {
            alert("Something went wrong. Try again. If this issue persists, email digitalhistorycanada@gmail.com")
        }
    }

    return(
        <div className="formPage">
            {!user ?
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "40vh"}}>
                    <input onClick={activate} className="btn btn-primary" type="submit" value="Activate your Account" />
                </div>
            :
                <Navigate to="/" />
            }
        </div>
    )
}

export default Activate