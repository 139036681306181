import { useState, useContext } from "react"
import AuthContext from "../context/AuthContext"

import "../App.css"

function BuildingSelector({itemBuildings, getItemBuildings}) {
    const [search, setSearch] = useState()
    const [results, setResults] = useState([])
    let {domain} = useContext(AuthContext)

    const changeSearch = e => {
        setSearch(e.target.value)
        if (e.target.value.length > 2) {
            fetch(`${domain}/api/buildings/?search=${e.target.value}`)
                .then(res => res.json())
                .then(data => {
                    setResults(data.features)
                })
        }
        else {
            setResults([])
        }
    }

    let building_array = []

    const sendBuilding = e => {
        building_array = itemBuildings
        if (!building_array.filter(building => building.id === e.target.id).length > 0) {
            building_array.push({name: e.target.innerHTML, id: e.target.id})
            getItemBuildings(building_array)
        }
        setSearch("")
        setResults([])
    }

    const removeBuilding = e => {
        building_array = itemBuildings
        building_array = building_array.filter(function(building) {
            return building.name !== e.target.innerHTML;
        })
        getItemBuildings(building_array)
    }
    
    return(
        <div>
            <div>
                <label for="buildingSearch">Buildings</label>
                <div className="buildingTagBox">
                {itemBuildings.length > 0 ?
                    itemBuildings.map(building => {
                        console.log(building)
                        return(
                            <div className="buildingTag" onClick={removeBuilding}><span>{building.name}</span></div>
                        )
                    })
                : <span>Select Buildings...</span>}
                </div>
                <input id="buildingSearch" type="text" value={search} onChange={changeSearch} placeholder="Building search..." class="form-control" />
                <div className="resultBoxBox">
                    {results.length > 0 ? results.map(building => {
                        return(
                            <div id={building.properties.id} value={building.properties.name} className="resultBox" onClick={sendBuilding}>{building.properties.name}</div>
                        )
                    }) : null}
                </div>
            </div>
        </div>
    )
}

export default BuildingSelector