import {createContext, useState, useEffect} from 'react'
import jwt_decode from 'jwt-decode';
import {useNavigate} from 'react-router-dom'

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {
    
    const [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    const [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwt_decode(localStorage.getItem('authTokens')) : null)
    const [loading, setLoading] = useState(true)
    const [startLogin, setStartLogin] = useState(false)

    const history = useNavigate()

    const domain = 'https://atlas.digitalhistory.ca'
    // const domain = 'http://localhost:8000'

    //Login function
    let loginUser = async (e) => {
        e.preventDefault()
        let response = await fetch(`${domain}/api/user/token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'email': e.target.email.value,
                'password': e.target.password.value
            })
        })
        let data = await response.json()
        if (response.status === 200) {
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            setStartLogin(false)
            localStorage.setItem('authTokens', JSON.stringify(data))
            history("/")
        }
        else if (response.status === 401) {
            alert("Incorrect email or password. Review your login information and try again.")
        } 
        else {
            alert('Login error. Try again.')
        }
    }

    let logoutUser = () => {
        setAuthTokens(null)
        setUser(null)
        localStorage.removeItem('authTokens')
        // history('/login')
    }

    let updateToken = async () => {
        let response = await fetch(`${domain}/api/user/token/refresh/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'refresh': authTokens?.refresh
            })
        })
        let data = await response.json()
        if (response.status === 200) {
            setAuthTokens(data)
            setUser(jwt_decode(data.access))
            localStorage.setItem('authTokens', JSON.stringify(data))
        } 
        else {
            logoutUser()
        }
        if (loading) {
            setLoading(false)
        }
    }

    const toggleLogin = () => {
        history("/")
        setStartLogin(!startLogin)
    }

    let contextData = {
        user: user,
        startLogin: startLogin,
        domain: domain,
        loginUser: loginUser,
        logoutUser: logoutUser,
        toggleLogin: toggleLogin,
    }

    useEffect(() => {

        if(loading) {
            updateToken()
        }

        const interval = setInterval(() => {
            if(authTokens) {
                updateToken()
            }
        }, 540000)
        return () => clearInterval(interval)
    }, [authTokens, loading])
    
    return(
        <AuthContext.Provider value={contextData} >
            {loading ? null : children}
        </AuthContext.Provider>
    )
}