import {useState, useContext} from 'react'
import AuthContext from '../context/AuthContext'
import {Button} from 'react-bootstrap'

const UserIcon = ({name, profile, menu}) => {

    const [open, setOpen] = useState(false)

    let {user, logoutUser, domain} = useContext(AuthContext)

    const toggleMenu = () => {
        setOpen(!open)
    }

    const resetPassword = async() => {
        if (user) {
            let response = await fetch(`${domain}/auth/users/reset_password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'email': user.email
                })
            })
            if (response.status === 204) {
                alert("Password reset link sent. Check your email.")
            } 
            else {
                alert("An error occurred. Try again.")
            }
        }
    }

    return (
        <>
            <div className="profileBox" onClick={toggleMenu}>
                <img src={profile} className="profileImage" />
                <span className="profileName">{name}</span>
            </div>
            {open && menu===true ? 
            <div className="logoutMenu">
                <span className="btn btn-primary logoutButton" onClick={logoutUser}>Logout</span>
                <span className="btn btn-secondary logoutButton" onClick={resetPassword}>Change Password</span>
            </div>
            : null}
        </>
    )
}

export default UserIcon