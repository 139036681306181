import { useState } from "react"

import "../App.css"

function LocationSearch({getPlace}) {
    const [search, setSearch] = useState()
    const [results, setResults] = useState([])

    const changeSearch = (e) => {
        setSearch(e.target.value)
        if (e.target.value.length > 2) {
            fetch(`https://geogratis.gc.ca/services/geoname/en/geonames.json?q=${e.target.value}*&num=10&theme=985`)
                .then(res => res.json())
                .then(data => {
                    setResults(data.items.slice(0,10))
                })
        }
        else {
            setResults([])
        }
    }

    const sendPlace = (e) => {
        let place = results.filter(function(place) {
            return place.name === e.target.id
        })
        getPlace(place[0])
        setSearch("")
        setResults([])
    }

    return(
        <div className="searchBox">
            <input style={{padding: 5, border: 0, borderRadius: 5}} type="text" value={search} onChange={changeSearch} placeholder="Location search..." />
            {results.map(place => {
                return(
                    <div id={place.name} className="resultBox" onClick={sendPlace}>{place.name}</div>
                )
            })}
        </div>
    )

}

export default LocationSearch