import { useEffect, useState, useCallback } from 'react'
import PinchZoomPan from "react-image-zoom-pan";


//Style
import '../App.css'
import left from '../icons/calcite/caret-left-32.svg'
import right from '../icons/calcite/caret-right-32.svg'


//Components
import ItemPopup from './itemPopup'

function ImageDisplay({item, inPopup, getPopupId}) {

    const [photo, setPhoto] = useState(1)

    const previousPhoto = () => {
        setPhoto(photo - 1)
    }

    const nextPhoto = () => {
        setPhoto(photo + 1)
    }

    useEffect(() => {
        setPhoto(1)
    },[item])

    const openPopup = (e) => {
        getPopupId(e.target.id)
    }

    return(
        <>
            {item.files.length > 0 ?
                <div className="imageContainer">
                    <div className="imageSides" onClick={photo > 1 ? previousPhoto : null}>
                        {photo > 1 ? 
                            <img className="icon" src={left} style={{padding: '2px'}} />
                        : null}
                    </div>
                    {item.type != 'video' ?
                        <div className="imageContainerContainer" >
                            {!inPopup ? 
                                <img id={item.id} onClick={openPopup} className="image" src={item.files[(photo-1)].image} />
                                : 
                                <PinchZoomPan maxScale={5} zoomButtons={false} position={'center'}>
                                    <img className="image" src={item.files[(photo-1)].image} />
                                </PinchZoomPan>
                            }
                        </div>
                    :null}
                    <div className="imageSides" onClick={photo < item.files.length ? nextPhoto : null}>
                        {photo < item.files.length ? 
                            <img className="icon" src={right} style={{padding: '2px'}} />
                        : null}
                    </div>
                </div>
            : (item.type === 'photograph' || item.type === 'postcard') && item.source === 'link' ?
                <div className="imageContainerContainer" style={{width: '100%', maxWidth: '100%'}} >
                    {!inPopup ? 
                        <img id={item.id} onClick={openPopup} className="image" src={item.external_url} />
                        : 
                        <PinchZoomPan maxScale={5} zoomButtons={false} position={'center'}>
                            <img className="image" src={item.external_url} />
                        </PinchZoomPan>
                    }
                </div>
            : item.type === 'video' && item.source === 'youtube' ? 
                <div className="imageContainer">
                    <iframe className="video" src={item.external_url} allowFullScreen></iframe>
                </div>
            : item.type === 'audio' && item.source === 'apple podcasts' ?
                <iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" width={250} height={200} style={{overflow: "hidden", background: "transparent"}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src={item.external_url}></iframe>
            : null}
            {/* Add option for other video display url */}
        </>
    )
}

export default ImageDisplay