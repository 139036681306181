//Styling
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "../App.css"

//Components
import ImageDisplay from './imageDisplay'

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

function InstitutionPopup ({institution, getInstitution, getPopupId}) {
    const closeInstitution = () => {
        getInstitution(null)
    }

    return(
        <>
        {institution ?
        <Modal show={true} onHide={closeInstitution} dialogClassName="modal-90w">
            <Modal.Header closeButton>
                <Modal.Title>{institution.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col style={{maxHeight: "50vh", overflowY: "scroll", overflowX: "hidden"}} xs={12} md={8}>
                            <h4>Information</h4>
                            <div className="institutionBox">
                                {institution.thumbnail ? 
                                    <div style={{display: 'flex', width: '100%'}}>
                                        <div style={{width: '45%', padding: '2.5%'}}>
                                            <img src={institution.thumbnail} style={{width: '100%'}} />
                                        </div>
                                        <div style={{width: '45%', padding: '2.5%'}}>
                                            <p>{institution.description}</p>
                                            {institution.website ?
                                                <div style={{padding: 5}}><a href={institution.website} target="_blank" className="btn btn-primary">Main Website</a></div>
                                                : null}
                                            {institution.cms_website ?
                                                <div style={{padding: 5}}><a href={institution.cms_website} target="_blank" className="btn btn-primary">Digital Collections</a></div>
                                                : null}
                                            {institution.donations_website ?
                                                <div style={{padding: 5}}><a href={institution.donations_website} target="_blank" className="btn btn-primary">Donate</a></div>
                                                : null}
                                        </div>
                                    </div>
                                :   <div>
                                        <p>{institution.description}</p>
                                        {institution.website ?
                                            <div style={{padding: 5}}><a href={institution.website} target="_blank" className="btn btn-primary">Main Website</a></div>
                                            : null}
                                        {institution.cms_website ?
                                            <div style={{padding: 5}}><a href={institution.cms_website} target="_blank" className="btn btn-primary">Digital Collections</a></div>
                                            : null}
                                        {institution.donations_website ?
                                            <div style={{padding: 5}}><a href={institution.donations_website} target="_blank" className="btn btn-primary">Donate</a></div>
                                            : null}
                                </div>}
                            {institution.hours.length > 0 ? 
                                <>
                                    <h5>Hours</h5>
                                    <Table striped bordered hover>
                                        <tbody>
                                            <tr>
                                                <td><b>Date</b></td>
                                                <td><b>Hours</b></td>
                                            </tr>
                                            {institution.hours.map(day => {
                                                return(
                                                    <tr>
                                                        <td>{capitalize(day.day)}</td>
                                                        <td>{day.opening_time.slice(0,-3) + ' - ' + day.closing_time.slice(0,-3)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </>
                            : null}
                            <h5>Admission</h5>
                            <Table striped bordered hover>
                                <tbody>
                                    <tr>
                                        <td><b>Group</b></td>
                                        <td><b>Rate</b></td>
                                    </tr>
                                    {institution.admission.map(set => {
                                        return(
                                            <tr>
                                                <td>{set.type}</td>
                                                <td>{set.amount > 0 ? set.amount : "Free"}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            </div>
                        </Col>
                        <Col style={{maxHeight: "50vh", overflowY: "scroll", overflowX: "hidden"}} xs={12} md={4}>
                            <h4>Items</h4>
                            {institution.items.map(item => {
                                return(
                                    <ImageDisplay item={item} inPopup={false} getPopupId={getPopupId} />
                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={closeInstitution}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        : null}
        </>
    )
}

export default InstitutionPopup;