import {Navigate, useNavigate} from 'react-router-dom'
import { useContext } from "react"
import AuthContext from "../context/AuthContext"

const Register = () => {
    let {user, domain} = useContext(AuthContext)
    const history = useNavigate()

    const register = async (e) => {
        e.preventDefault()
        let response = await fetch(`${domain}/auth/users/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'first_name': e.target.first_name.value,
                'last_name': e.target.last_name.value,
                'email': e.target.email.value,
                'password': e.target.password.value,
                're_password': e.target.re_password.value
            })
        })
        let data = await response.json()
        if (response.status === 201) {
            alert("Your account has successfully been created. Check your email to verify your account.")
            history("/")
        } else {
            if (data.non_field_errors && data.non_field_errors[0] === "The two password fields didn't match.") {
                alert("The two passwords did not match!")
            }
            else if (data.email && data.email[0] === "user with this email address already exists.") {
                alert("A user with this email already exists! Log in or register with a different email.")
            }
            else {
                alert("An unknown error occurred. Try again. If this issue persists, email digitalhistorycanada@gmail.com")
            }
            // console.log(data)
        }
    }

    return (
        <div className="formPage">
            {!user ?
            
            <form onSubmit={register}>
                <h2>Create your Account</h2>
                <div class="mb-3">
                    <label for="first_name" class="form-label">First name</label>
                    <input id="first_name" class="form-control" type="text" name="first_name" placeholder="Email" />
                </div>
                <div class="mb-3">
                    <label for="last_name" class="form-label">Last name</label>
                    <input id="last_name" class="form-control" type="text" name="last_name" placeholder="Email" />
                </div>
                <div class="mb-3">
                    <label for="email" class="form-label">Email address</label>
                    <input id="email" class="form-control" type="email" name="email" placeholder="Email" />
                </div>
                <div class="mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input id="password" type="password" class="form-control" name="password" placeholder="Password" />
                </div>
                <div class="mb-3">
                    <label for="re_password" class="form-label">Confirm Password</label>
                    <input id="re_password" type="password" class="form-control" name="re_password" placeholder="Password" />
                </div>
                <input class="btn btn-primary" type="submit" value="Register" />
            </form>
            :
                <Navigate to="/" />
            }
        </div>
    )
}

export default Register
