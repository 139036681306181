//Imports
import React, {useState, useCallback, useEffect} from 'react';
import { useSearchParams, useParams } from 'react-router-dom';

//Context
import { useContext } from 'react';
import AuthContext from '../context/AuthContext';

//Coords
import {fromLonLat} from "ol/proj";

//Components
import MainMap from "../components/map"
import ImageDisplay from '../components/imageDisplay';
import ItemUpload from '../components/itemUpload';
import BuildingPopup from '../components/buildingPopup';
import PlaquePopup from '../components/plaquePopup';
import InstitutionPopup from '../components/institutionPopup';
import LocationSearch from '../components/locationSearch';
import ItemPopup from '../components/itemPopup';
import LoginPopup from '../components/loginPopup';


//Icons
import right_arrow from "../icons/right-arrow.svg"
import left_arrow from "../icons/left-arrow.svg"
import add_icon from "../icons/add.svg"
import plaque_icon from '../icons/plaque.png'
import image_icon from '../icons/image.png'
import video_icon from '../icons/video.png'
import audio_icon from '../icons/audio.png'
import institution_icon from '../icons/institution.png'

//Bootstrap
import Accordion from 'react-bootstrap/Accordion'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/esm/Button';

import '../App.css';


//Map Wrapper
function MapWrapper() {

    let {user, startLogin, toggleLogin, domain} = useContext(AuthContext)
    let {bookmark} = useParams()

    let [params, setParams] = useSearchParams();
    //Get URL Info
    useEffect(() => {
        const param_item = params.get('item')
        setPopupId(param_item)
    }, [])

    //Control Item Popup
    const [popupId, setPopupId] = useState()

    const getPopupId = useCallback(id => {
        setPopupId(id)
    })

    const closePopup = useCallback(() => {
        setPopupId(null)
    })

    const getZoomCoords = useCallback(coords => {
        setPlace(coords)
    })

    const [panelOpen, setPanelOpen] = useState(true)

    const togglePanel = () => {
        setPanelOpen(!panelOpen)
    }

    const [photoInfo, setPhotoInfo] = useState()

    const getPhotos = useCallback((id_array) => {
        let photoIds = id_array
        if (photoIds.length > 0) {
            Promise.all(photoIds.map(id => 
                fetch(`${domain}/api/item/${id}/`)
                    .then(res => res.json())
            )).then(data => {
                setPhotoInfo(data)
            })
        }
    })

    //Open panel when photo is clicked
    useEffect(() => {
        if (!panelOpen){
            setPanelOpen(true)
        }
    }, [photoInfo])

    const [building, setBuilding] = useState()

    const getBuilding = useCallback((id) => {
        if(id) {
            fetch(`${domain}/api/building/${id}`)
                .then(res => res.json())
                .then(data => {
                    setBuilding(data)
                })
        }
        else {
            setBuilding(null)
        }
    })

    const [plaque, setPlaque] = useState()

    const getPlaque = useCallback((id) => {
        if(id) {
            fetch(`${domain}/api/plaque/${id}`)
                .then(res => res.json())
                .then(data => {
                    setPlaque(data.properties)
                })
        }
        else {
            setPlaque(null)
        }
    })

    const [institution, setInstitution] = useState()

    const getInstitution = useCallback((id) => {
        if(id) {
            fetch(`${domain}/api/institution/${id}`)
                .then(res => res.json())
                .then(data => {
                    setInstitution(data)
                })
        }
        else {
            setInstitution(null)
        }
    })

    //Point Adding
    const [add, setAdd] = useState(false)

    const [addPoint, setAddPoint] = useState(false)

    const changeAddPoint = () => {
        setAddPoint(!addPoint)
    }

    const startAdd = () => {
        if (!add) {
            setAdd(true)
        }
    }

    const finishAdd = useCallback(() => {
        setAdd(false)
        setAddPoint(false)
        setCoords()
    })

    const [coords, setCoords] = useState()

    const getCoords = useCallback((coords) => {
        setCoords(coords)
    })

    //Accordion Menu
    const [activeKey, setActiveKey] = useState(2)

    const changeActiveKey = useCallback((key) => {
        setActiveKey(key)
    })

    const setActiveKeyClick = (e) => {
        if (activeKey === parseInt(e.target.id)) {
            setActiveKey(null)
        }
        else {
            setActiveKey(parseInt(e.target.id))
        }
    }

    //Layers Handling
    const [photosLayer, setPhotosLayer] = useState(true)

    // const togglePhotosLayer = () => {
    //     setPhotosLayer(!photosLayer)
    // }

    const [buildingsLayer, setBuildingsLayer] = useState(false)

    // const toggleBuildingsLayer = () => {
    //     if (buildingsLayer) {
    //         setBuilding(null)
    //     }
    //     setBuildingsLayer(!buildingsLayer)
    // }

    const [plaquesLayer, setPlaquesLayer] = useState(false)

    // const togglePlaquesLayer = () => {
    //     setPlaquesLayer(!plaquesLayer)
    // }

    const toggleRadioLayers = e => {
        if (e.target.id === "togglePhotos") {
            setPhotosLayer(true)
            setBuilding(null)
            setBuildingsLayer(false)
            setPlaquesLayer(false)
        }
        else if (e.target.id === "togglePlaques") {
            setPhotosLayer(false)
            setBuilding(null)
            setBuildingsLayer(false)
            setPlaquesLayer(true)
        }
        else if (e.target.id === "toggleBuildings") {
            setPhotosLayer(false)
            setBuildingsLayer(true)
            setPlaquesLayer(false)
        }
    }

    const [institutionsLayer, setInstitutionsLayer] = useState(true)

    const toggleInstitutionsLayer = () => {
        setInstitutionsLayer(!institutionsLayer)
    }

    //Location Search Handling
    const [place, setPlace] = useState()

    const getPlace = useCallback((place) => {
        setPlace(fromLonLat([place.longitude,place.latitude]))
    })

    //Historical Map handling
    const [rasters, setRasters] = useState([])

    const getExtent = useCallback((extent) => {
        fetch(`${domain}/api/rasters/?bbox=${extent}`)
            .then(res => res.json())
            .then(data => {
                setRasters(data)
            })
    })

    const [activeRasters, setActiveRasters] = useState([])
    const [rasterTrigger, setRasterTrigger] = useState(false)

    let raster_array = []
    let index

    const onRasterClick = (e) => {
        raster_array = activeRasters

        let id = parseInt(e.target.id.split("-")[1])

        if (e.target.checked) {
            raster_array.push(id)
        }
        else {
            index = raster_array.indexOf(id);
            if (index !== -1) {
                raster_array.splice(index, 1);
            }
        }
        setActiveRasters(raster_array)
        setRasterTrigger(!rasterTrigger)
    }

    const [opacityChange, setOpacityChange] = useState()

    const onOpacityChange = (e) => {
        setOpacityChange({id: parseInt(e.target.id.split("-")[1]), value: parseFloat(e.target.value)})
    }

    const [opacities, setOpacities] = useState([])

    const getOpacities = useCallback((opacities) => {
        setOpacities(opacities)
    })

    //Building Categories
    const [buildingCats, setBuildingCats] = useState([])

    useEffect(() => {
        fetch(`${domain}/api/buildings/categories/`)
            .then(res => res.json())
            .then(data => {
                setBuildingCats(data)
            })
    }, [])

    //Handle Zoom
    const [zoom, setZoom] = useState(7)

    const changeZoom = useCallback(zoom => {
        setZoom(zoom)
    })

    return(
        <div className="mapContainer">
            <MainMap photosLayerToggle={photosLayer} buildingsLayerToggle={buildingsLayer} plaquesLayerToggle={plaquesLayer} institutionsLayerToggle={institutionsLayer} getPhotos={getPhotos} getBuilding={getBuilding} getPlaque={getPlaque} getInstitution={getInstitution} addPoint={addPoint} coords={coords} getCoords={getCoords} changeActiveKey={changeActiveKey} place={place} getExtent={getExtent} activeRasters={activeRasters} rasterTrigger={rasterTrigger} opacityChange={opacityChange} opacities={opacities} getOpacities={getOpacities} zoom={zoom} changeZoom={changeZoom} bookmark={bookmark} />
            <LocationSearch getPlace={getPlace} />
            {panelOpen ?
                <>
                    <div className="infoPanel">
                    <div className="accordion accordion-flush" id="accordionFlushExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingThree">
                            <button id="2" onClick={setActiveKeyClick} className={activeKey === 2 ? "accordion-button open" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                Legend
                            </button>
                            </h2>
                            <div id="flush-collapseThree" className={activeKey === 2 ? "accordion-collapse open" : "accordion-collapse collapse"} aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                            <div className="legendContainer">
                                <h5>Layers:</h5>
                                <div className="checkboxes">
                                    <div>
                                        <input id="togglePhotos" type="radio" value={photosLayer} onClick={toggleRadioLayers} defaultChecked={photosLayer} name="radioLayers" />
                                        <label htmlFor="togglePhotos">Items</label>
                                    </div>
                                    <div>
                                        <input id="toggleBuildings" type="radio" value={buildingsLayer} onClick={toggleRadioLayers} defaultChecked={buildingsLayer} name="radioLayers" />
                                        <label htmlFor="toggleBuildings">Buildings</label>
                                    </div>
                                    <div>
                                        <input id="togglePlaques" type="radio" value={plaquesLayer} onClick={toggleRadioLayers} defaultChecked={plaquesLayer} name="radioLayers" />
                                        <label htmlFor="togglePlaques">Plaques</label>
                                    </div>
                                    <div>
                                        <input id="toggleInstitutions" type="checkbox" value={institutionsLayer} onClick={toggleInstitutionsLayer} defaultChecked={institutionsLayer} />
                                        <label htmlFor="toggleInstitutions">Museums</label>
                                    </div>
                                </div>
                                <h5>Icons:</h5>
                                    {photosLayer ? 
                                        <>
                                        <div className="legendIconContainer">
                                            <img src={image_icon} className="legendIcon" />
                                            <span>Image</span>
                                        </div>
                                        <div className="legendIconContainer">
                                            <img src={video_icon} className="legendIcon" />
                                            <span>Video</span>
                                        </div>
                                        <div className="legendIconContainer">
                                            <img src={audio_icon} className="legendIcon" />
                                            <span>Audio</span>
                                        </div>
                                        </>
                                        : null
                                    }
                                    {buildingsLayer && buildingCats.length > 0 ?
                                        buildingCats.map(cat => {
                                            return(
                                                <div className="legendIconContainer">
                                                    <img src={cat.icon} className="legendIcon" />
                                                    <span>{cat.name}</span>
                                                </div>
                                            )
                                        })
                                        : null
                                    }
                                    {plaquesLayer ? 
                                        <div className="legendIconContainer">
                                            <img src={plaque_icon} className="legendIcon" />
                                            <span>Plaque</span>
                                        </div>
                                        : null
                                    }
                                    {institutionsLayer ? 
                                        <div className="legendIconContainer">
                                            <img src={institution_icon} className="legendIcon" />
                                            <span>Museum</span>
                                        </div>
                                        : null
                                    }
                                <h5>Historical Maps:</h5>
                                <div className="checkboxes">
                                    {rasters.length > 0 ? 
                                        rasters.map(raster => {
                                            return(
                                                <div>
                                                    <input id={'rasterCheck-' + raster.id} type="checkbox" onClick={onRasterClick} defaultChecked={activeRasters.includes(raster.id) ? true : false} />
                                                    <label htmlFor={'rasterCheck-' + raster.id}>{raster.name}</label>
                                                    {activeRasters.includes(parseInt(raster.id)) ? 
                                                        <input id={'rasterOpacity-' + raster.id} onChange={onOpacityChange} type="range" min={0} max={1} step={0.01} defaultValue={opacities.length > 0 ? opacities.find(opacity => opacity.layer === `raster-${raster.id}`) ? opacities.find(opacity => opacity.layer === `raster-${raster.id}`).opacity : 1 : 1} />
                                                    : null}
                                                </div>
                                            )
                                        })
                                    : <span>There are no historical maps that cover this area.</span>}
                                    {/* <span>There are no historical maps that cover this area.</span> */}
                                </div>
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne">
                            <button id="0" onClick={setActiveKeyClick} className={activeKey === 0 ? "accordion-button open" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                Tools
                            </button>
                            </h2>
                            <div id="flush-collapseOne" className={activeKey === 0 ? "accordion-collapse open" : "accordion-collapse collapse"} aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                            <div className="iconLabelDiv">
                                <div className="iconDiv" style={addPoint ? {backgroundColor: '#e7f1ff'} : null}>
                                    <img src={add_icon} onClick={user ? changeAddPoint : toggleLogin} className="legendIcon" style={{height: "3vh"}} />
                                </div>
                                <span>{user ? 'Add item' : 'Log in to add items'}</span>
                            </div>
                            <div className="iconLabelDiv">
                                <div className="legendIconContainer">
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLScpJPb_2DTspRz_uqXFoSsFV8-je6O3DBFvsc0_kWjD4xmpzg/viewform?usp=sf_link" target="blank_"><img src={institution_icon} className="legendIcon" style={{height: "3vh"}} /></a>
                                    <span>Add your organization to the atlas</span>
                                </div>
                            </div>
                            <br />
                            {addPoint ?
                                <>
                                    <Alert variant="primary" style={{fontSize: 12, padding: 4}}>Click on the map to add the location of your item. Click "Add Item" when you have selected your location.</Alert>
                                    {coords ? <p>{"Lat: " + coords[1].toPrecision(7) + ","}</p>: null}
                                    {coords ? <p>{"Lng: " + coords[0].toPrecision(7)}</p> : null}
                                    {coords ? <Button onClick={startAdd}>Add Item</Button> : null}
                                </>
                                : null
                            }
                            {add ? <ItemUpload add={add} finishAdd={finishAdd} coords={coords} /> : null}
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingTwo">
                            <button id="1" onClick={setActiveKeyClick} className={activeKey === 1 ? "accordion-button open" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                View Items and Images
                            </button>
                            </h2>
                            <div id="flush-collapseTwo" className={activeKey === 1 ? "accordion-collapse open" : "accordion-collapse collapse"} aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <div className="mainItemContainer">
                                    {photoInfo ?
                                        photoInfo.map(item => {
                                            // if (item.files.length > 0) {
                                            return(
                                                <div key={item.id}>
                                                    <ImageDisplay item={item} inPopup={false} getPopupId={getPopupId} />
                                                </div>
                                            )
                                            // }
                                        })
                                    : null}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <span className="panelCloser" onClick={togglePanel}><img className="arrow" src={left_arrow} /></span>
                </>
                : <span className="panelOpener" onClick={togglePanel}><img className="arrow" src={right_arrow} /></span>
            }
            {building ? <BuildingPopup building={building} getBuilding={getBuilding} getPopupId={getPopupId} /> : null}
            {plaque ? <PlaquePopup plaque={plaque} getPlaque={getPlaque} /> : null}
            {institution ? <InstitutionPopup institution={institution} getInstitution={getInstitution} getPopupId={getPopupId} /> : null}
            {popupId ? <ItemPopup item_id={popupId} closePopup={closePopup} getZoomCoords={getZoomCoords} /> : null}
            {startLogin ? <LoginPopup /> : null}
            {zoom <= 6 ? <div className="zoomAlertDiv"><Alert variant="warning" className="zoomAlert">Zoom in to see items!</Alert></div> : null}
        </div>
    )
}

export default MapWrapper