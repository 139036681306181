import { useState, useContext } from "react"
import AuthContext from "../context/AuthContext"

import Button from "react-bootstrap/Button"

import reply_icon from '../icons/reply.svg'

function WriteComment({itemId, replyTo, doRefresh}) {
    let {user, toggleLogin, authTokens, domain} = useContext(AuthContext)
    const [commentText, setCommentText] = useState("")

    const changeCommentText = (e) => {
        setCommentText(e.target.value)
    }

    const [reply, setReply] = useState(false)

    const toggleReply = () => {
        setReply(!reply)
    }

    const submitComment = () => {
        if (commentText.length > 0) {
            let postData
            if (itemId){
                postData = {
                    item: itemId,
                    text: commentText,
                    author: user ? user.user_id : null
                }
            }
            else if (replyTo) {
                postData = {
                    reply_to: replyTo,
                    text: commentText,
                    author: user ? user.user_id : null
                }
            }
            fetch(`${domain}/api/comment/post/`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'JWT ' + JSON.parse(localStorage.getItem('authTokens')).access
                },
                method: "POST",
                body: JSON.stringify(postData)
            }).then(res => res.json()).then(data => {
                console.log(data)
                doRefresh()
            }).catch(err => {
                console.log(err)
            })
        }
        setReply(false)
    }

    return(
        <>
            {user ?
                <div style={{width: "100%"}}>
                    {reply ?
                        <>
                            <input style={{width: "80%"}} id="comment" value={commentText} onChange={changeCommentText} />
                            <Button variant="secondary" onClick={submitComment}>Submit</Button>
                        </>
                        : <img src={reply_icon} onClick={toggleReply} style={{position: "relative", right: 0, height: "100%", cursor: "pointer"}} />
                    }
                </div>
                : !replyTo ? 
                    <div style={{marginTop: "5px"}}>
                        <button className="btn btn-primary" onClick={toggleLogin}>Login</button><span>&nbsp; to write a comment</span>
                    </div> 
                : null

            }
        </>
    )
}

export default WriteComment