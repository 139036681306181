import './App.css';
import MapWrapper from './pages/mapwrapper';
// import Login from './pages/login';
import Header from './components/header';
import Footer from './components/footer';
import Register from './pages/register';
import Activate from './pages/activate';
import PasswordReset from './pages/passwordreset';

import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
// import PrivateRoute from './utils/privateRoute';
import {AuthProvider} from './context/AuthContext'

import { useEffect } from 'react';
import ReactGA from 'react-ga';

import ResizeObserver from 'resize-observer-polyfill';
 
const ro = new ResizeObserver((entries, observer) => {
    for (const entry of entries) {
        const {left, top, width, height} = entry.contentRect;
 
        console.log('Element:', entry.target);
        console.log(`Element's size: ${ width }px x ${ height }px`);
        console.log(`Element's paddings: ${ top }px ; ${ left }px`);
    }
});
 
ro.observe(document.body);

function App() {

  useEffect(() => {
    //https://www.youtube.com/watch?v=H-D-kaCjKfc
    ReactGA.initialize('G-61X3NQJ924')
    //Report page view
    ReactGA.pageview('/')
  },[])

  return (
    <BrowserRouter>
      <AuthProvider>
        <Header />
        <Routes>
          <Route path='/:bookmark' element={<MapWrapper />} exact />
          <Route path='/' element={<MapWrapper />} exact />
          <Route path='/register' element={<Register />} exact />
          <Route exact path="/activate/:uid/:token" element={<Activate />}/>
          <Route exact path="/password/reset/confirm/:uid/:token" element={<PasswordReset />}/>
          <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
        <Footer />
      </AuthProvider>
    </BrowserRouter>
  )
}

export default App;