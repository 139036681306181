import { useState, useEffect, useRef, useContext } from "react";
import AuthContext from "../context/AuthContext";

import '../App.css';
import "ol/ol.css";

//OpenLayers
import Map from 'ol/Map'
import View from 'ol/View'
import {Style, Fill, Stroke, Icon, Circle as CircleStyle, Text} from "ol/style";
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer'
import {OSM, Cluster, Vector as VectorSource, XYZ} from 'ol/source'
import {fromLonLat, toLonLat} from "ol/proj";
import {Attribution, defaults as defaultControls} from "ol/control"
import {GeoJSON} from 'ol/format'
import {Draw, Modify} from 'ol/interaction'
import {transformExtent} from 'ol/proj'

//Geolocation
// import Geolocation from 'ol/Geolocation';

//Icons
import house from '../icons/house.png'
import plaque from '../icons/plaque.png'
import image from '../icons/image.png'
import video from '../icons/video.png'
import audio from '../icons/audio.png'
import institution from '../icons/institution.png'

function MainMap({photosLayerToggle, buildingsLayerToggle, plaquesLayerToggle, institutionsLayerToggle, getPhotos, getBuilding, getPlaque, getInstitution, addPoint, coords, getCoords, changeActiveKey, place, getExtent, activeRasters, rasterTrigger, opacityChange, opacities, getOpacities, zoom, changeZoom, bookmark}) {

    let {domain} = useContext(AuthContext)
    
    const [map, setMap] = useState();
    const mapElement = useRef();

    const mapRef = useRef()
    mapRef.current = map;

    //Set up base layer
    const [baseLayer, setBaseLayer] = useState()

    const baseRef = useRef()
    baseRef.current = baseLayer

    useEffect(() => {
        const initialBaseLayer = new TileLayer();
        setBaseLayer(initialBaseLayer)
    }, [])

    // const osmSource = new OSM({
    //     attributions: "Basemap from OpenStreetMap."
    // })

    // const esriTopoSource = new XYZ({
    //     attributions:
    //       'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
    //       'rest/services/World_Topo_Map/MapServer">ArcGIS</a>',
    //     url:
    //       'https://server.arcgisonline.com/ArcGIS/rest/services/' +
    //       'World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
    // })

    const esriSatelliteSource = new XYZ({
        attributions:
          'Tiles © <a href="https://services.arcgisonline.com/ArcGIS/' +
          'rest/services/World_Imagery/MapServer">ArcGIS.</a>',
        url: 'http://server.arcgisonline.com/arcgis/rest/services/' + 
        'World_Imagery/MapServer/tile/{z}/{y}/{x}',
        // 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=JOsKVen7fj3qXqq2AE1F'
    })

    useEffect(() => {
        if (baseLayer) {
            baseLayer.setSource(esriSatelliteSource)
        }
    }, [baseLayer])

    //GeoJSON Layer
    //Clustering: https://openlayers.org/en/latest/examples/clusters-dynamic.html?msclkid=b2fc8aceaeb611ec98a174c7db8f5f13
    const [photoPointsLayer, setPhotoPointsLayer] = useState()
    const photoPointsRef = useRef()
    photoPointsRef.current = photoPointsLayer;

    const imageIcon = new Icon({
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 0.04,
        src: image,
    });
    
    const videoIcon = new Icon({
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 0.04,
        src: video,
    });

    const audioIcon = new Icon({
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 0.04,
        src: audio,
    });

    const [buildingsLayer, setBuildingsLayer] = useState()
    const buildingsRef = useRef()
    buildingsRef.current = buildingsLayer;

    const buildingIcon = new Icon({
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 0.01,
        src: house,
    });

    const iconStyle = new Style({
        image: buildingIcon
    });

    const [plaquesLayer, setPlaquesLayer] = useState()
    const plaquesRef = useRef()
    plaquesRef.current = plaquesLayer;

    const plaqueIcon = new Icon({
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 0.05,
        src: plaque,
    });

    const plaqueIconStyle = new Style({
        image: plaqueIcon
    });

    const [institutionsLayer, setInstitutionsLayer] = useState()
    const institutionsRef = useRef()
    institutionsRef.current = institutionsLayer;

    const institutionIcon = new Icon({
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        scale: 0.05,
        src: institution,
    });

    const institutionIconStyle = new Style({
        image: institutionIcon
    });

    const [coverageLayer, setCoverageLayer] = useState()
    const coverageRef = useRef()
    coverageRef.current = coverageLayer;

    const [namesLayer, setNamesLayer] = useState()
    const namesRef = useRef()
   namesRef.current = namesLayer;

    //Functions for fetching from GeoJSON API
    async function getGeoJSON(url) {
        const res = await fetch(url)
        const data = res.json()
        return data;
    }

    async function jsonToFeatures(url) {
        const data = await getGeoJSON(url)
        const features = (new GeoJSON()).readFeatures(data)
        return features;
    }

    async function jsonToFeaturesReproject(url) {
        const data = await getGeoJSON(url)
        const features = (new GeoJSON({
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        })).readFeatures(data)
        return features;
    }

    //Create layers
    useEffect(() => {
        //https://openlayers.org/en/latest/examples/cluster.html?msclkid=b2fc43bfaeb611ec91aca79d4ebeb590
        //https://openlayers.org/en/latest/examples/clusters-dynamic.html?msclkid=b2fc8aceaeb611ec98a174c7db8f5f13
        //https://openlayers.org/en/latest/examples/earthquake-clusters.html?msclkid=b2fca415aeb611ecb5cc071fe322ac73
        jsonToFeatures(`${domain}/api/items/?all=n`)
            .then(features => {
                const photoPoints = new VectorLayer({
                    source: new Cluster( {
                        distance: 200,
                        source: new VectorSource({
                            features: features
                        }),
                    }),
                    zIndex: 999,
                    style: function (feature) {
                        let style
                        const size = feature.get('features').length
                        if (size > 1) {
                            style = new Style({
                                image: new CircleStyle({
                                radius: 12,
                                //   stroke: new Stroke({
                                //     color: '#fff',
                                //   }),
                                fill: new Fill({
                                    color: '#fff',
                                }),
                                }),
                                text: new Text({
                                    text: size.toString(),
                                    fill: new Fill({
                                        color: '#000',
                                    }),
                                    scale: 1.2,
                                }),
                            });
                        }
                        else {
                            style = new Style({
                                image: feature.get('features')[0].values_.type === "video" ? videoIcon : feature.get('features')[0].values_.type === "audio" ? audioIcon : imageIcon
                            })
                        }
                        return style;
                    },
                })
                photoPoints.set('name', 'photoPoints')
                setPhotoPointsLayer(photoPoints)
            })

        jsonToFeatures(`${domain}/api/buildings/?all=n`)
            .then(features => {
                const buildings = new VectorLayer({
                    source: new Cluster( {
                        distance: 10,
                        source: new VectorSource({
                            features: features
                        }),
                    }),
                    zIndex: 998,
                    style: function(feature) {
                        let style
                        const size = feature.get('features').length
                        if (size > 1) {
                            style = new Style({
                                image: new CircleStyle({
                                radius: 12,
                                //   stroke: new Stroke({
                                //     color: '#fff',
                                //   }),
                                fill: new Fill({
                                    color: '#fff',
                                }),
                                }),
                                text: new Text({
                                    text: size.toString(),
                                    fill: new Fill({
                                        color: '#000',
                                    }),
                                    scale: 1.2,
                                }),
                            });
                        }
                        else {
                            const building = feature.get('features')[0]
                            if(building.values_.building_category) {
                                let icon = new Icon({
                                    anchorXUnits: 'fraction',
                                    anchorYUnits: 'fraction',
                                    scale: 0.05,
                                    src: building.values_.building_category.icon,
                                })
                                let style = new Style({
                                    image: icon
                                })
                                return style;
                            }
                            else {
                                return iconStyle
                            }
                        }
                        return style;
                        
                    }
                })
                buildings.set('name', 'buildings')
                setBuildingsLayer(buildings)
            })

        jsonToFeatures(`${domain}/api/plaques/?all=n`)
            .then(features => {
                const plaques = new VectorLayer({
                    source: new VectorSource({
                        features: features
                    }),
                    zIndex: 997,
                    style: plaqueIconStyle
                })
                plaques.set('name', 'plaques')
                setPlaquesLayer(plaques)
            })

        jsonToFeatures(`${domain}/api/institutions/?all=n`)
            .then(features => {
                const institutions = new VectorLayer({
                    source: new Cluster( {
                        distance: 20,
                        source: new VectorSource({
                            features: features
                        }),
                    }),
                    zIndex: 999,
                    style: function (feature) {
                        let style
                        const size = feature.get('features').length
                        if (size > 1) {
                            style = new Style({
                                image: new CircleStyle({
                                radius: 12,
                                //   stroke: new Stroke({
                                //     color: '#fff',
                                //   }),
                                fill: new Fill({
                                    color: '#fff',
                                }),
                                }),
                                text: new Text({
                                    text: size.toString(),
                                    fill: new Fill({
                                        color: '#000',
                                    }),
                                    scale: 1.2,
                                }),
                            });
                        }
                        else {
                            style = institutionIconStyle
                        }
                        return style;
                    }
                })
                institutions.set('name', 'institutions')
                setInstitutionsLayer(institutions)
            })

        jsonToFeatures(`${domain}/api/coverage/`)
            .then(features => {
                const coverage = new VectorLayer({
                    source: new VectorSource({
                        features: features
                    }),
                    zIndex: 997,
                    style: new Style({
                        stroke: new Stroke({
                            color: 'rgba(50, 173, 89, 0.8)',
                            width: 2,
                        }),
                        fill: new Fill({
                            color: 'rgba(50, 173, 89, 0.3)'
                        }),
                    })
                })
                coverage.set('name', 'coverage')
                setCoverageLayer(coverage)
            })

            jsonToFeaturesReproject(`https://geogratis.gc.ca/services/geoname/en/geonames?bbox=0,0,0,0&concise=CITY,TOWN,VILG,HAM,UNP`)
                .then(features => {
                    const names = new VectorLayer({
                        source: new VectorSource({
                            features: features,
                            attributions: "Place names from NRCAN's GeoNames Service.",
                        }),
                        zIndex: 997,
                        style: function(feature) {
                                const name = feature.get('name')
                                const style = new Style({
                                    text: new Text({
                                        text: name,
                                        fill: new Fill({
                                            color: '#fff',
                                        }),
                                        stroke: new Stroke({
                                            color: '#000',
                                            width: 3
                                        }),
                                        scale: 1.2,
                                        overflow: true
                                    })
                                });
                                return style;
                            },
                        declutter: true,
                        })
                    names.set('name', 'names')
                    setNamesLayer(names)
                })
    }, [])


    //Features loading
    let extent;

    let selected;
    useEffect(() => {
        if (map) {
            map.on('moveend', function () {
                extent = map.getView().calculateExtent()
                getExtent(extent)
                changeZoom(map.getView().getZoom())
                if(map.getView().getZoom() > 6) {
                    jsonToFeatures(`${domain}/api/items/?bbox=${extent}`)
                        .then(features => {
                            photoPointsLayer.setSource(new Cluster({
                                distance: 20,
                                source: new VectorSource({
                                    features: features
                                }),
                            }))
                        })
                    jsonToFeatures(`${domain}/api/buildings/?bbox=${extent}`)
                        .then(features => {
                            buildingsLayer.setSource(new Cluster( {
                                distance: 10,
                                source: new VectorSource({
                                    features: features
                                }),
                            }))
                        })
                    // coverageLayer.setSource(new VectorSource())
                }
                else {
                    photoPointsLayer.setSource(new VectorSource())
                    buildingsLayer.setSource(new VectorSource())
                }
                // jsonToFeatures(`${domain}/api/coverage/`)
                //     .then(features => {
                //         coverageLayer.setSource(new VectorSource({
                //             features: features
                //         }))
                //     })
                jsonToFeatures(`${domain}/api/plaques/?bbox=${extent}`)
                    .then(features => {
                        plaquesLayer.setSource(new VectorSource({
                            features: features
                        }))
                    })

                jsonToFeatures(`${domain}/api/institutions/?bbox=${extent}`)
                    .then(features => {
                        institutionsLayer.setSource(new Cluster( {
                            distance: 20,
                            source: new VectorSource({
                                features: features
                            }),
                        }))
                    })             
                jsonToFeaturesReproject(`https://geogratis.gc.ca/services/geoname/en/geonames?bbox=${transformExtent(extent,'EPSG:3857','EPSG:4326')}&concise=CITY,TOWN,VILG,HAM,UNP`)
                    .then(features => {
                        namesLayer.setSource(new VectorSource({
                            features: features,
                            attributions: "Place names from NRCAN's GeoNames Service.",
                        }))
                    })
            })
        }
    }, [map])

    //Click handling

    const [clickPixel, setClickPixel] = useState([])

    useEffect(() => {
        if (map && buildingsLayer && photoPointsLayer && plaquesLayer && institutionsLayer) {
            map.on('singleclick', e => {
                setClickPixel(e.pixel)
            })
        }
    }, [map, photoPointsLayer, buildingsLayer, plaquesLayer, institutionsLayer])

    useEffect(() => {
        if (map && buildingsLayer && photoPointsLayer && plaquesLayer && institutionsLayer) {
            if (photosLayerToggle) {
                let id_array = [];
                photoPointsLayer.getFeatures(clickPixel).then(clickedFeatures => {
                    if (clickedFeatures.length) {
                        const features = clickedFeatures[0].get('features');
                        id_array = [];
                        for (let x=0; x<features.length; x++) {
                            id_array.push(features[x].values_.id)
                        }
                        getPhotos(id_array)
                        changeActiveKey(1)
                    }
                })
            }
            if (buildingsLayerToggle) {
                buildingsLayer.getFeatures(clickPixel).then(clickedBuildings => {
                    if (clickedBuildings.length) {
                        const features = clickedBuildings[0].get('features')
                        if (features.length === 1) {
                            getBuilding(features[0].values_.id)
                        }                   
                    }
                })
            }
            if (plaquesLayerToggle) {
                plaquesLayer.getFeatures(clickPixel).then(clickedPlaques => {
                    if (clickedPlaques.length) {
                        getPlaque(clickedPlaques[0].values_.id)
                    }
                })
            }
            if (institutionsLayerToggle) {
                institutionsLayer.getFeatures(clickPixel).then(clickedInstitutions => {
                    if (clickedInstitutions.length) {
                        const features = clickedInstitutions[0].get('features')
                        if (features.length === 1) {
                            getInstitution(features[0].values_.id)
                        }
                    }
                })
            }
        }
    }, [map, clickPixel])

    useEffect(() => {
        if (map && !addPoint) {
            extent = map.getView().calculateExtent()
            zoom = map.getView().getZoom()
            if(zoom > 12) {
                jsonToFeatures(`${domain}/api/items/?bbox=${extent}`)
                    .then(features => {
                        photoPointsLayer.setSource(new Cluster({
                            distance: 20,
                            source: new VectorSource({
                                features: features
                            }),
                        }))
                    })
            }
            else {
                photoPointsLayer.setSource(new VectorSource())
            }
        }
    }, [map, addPoint])

    const [draw, setDraw] = useState()
    const [modify, setModify] = useState()
    const [drawLayer, setDrawLayer] = useState()

    const drawRef = useRef()
    drawRef.current = draw;

    const modifyRef = useRef()
    modifyRef.current = modify;

    const drawLayerRef = useRef()
    drawLayerRef.current = drawLayer;

    let drawSource;

    useEffect(() => {
        if (map) {
            drawSource = new VectorSource()
            setDrawLayer(new VectorLayer({source: drawSource}))
            let initialDraw = new Draw({
                source: drawSource,
                type: "Point",
            })
            let initialModify = new Modify({
                source: drawSource
            })
            setDraw(initialDraw)
            setModify(initialModify)
        }
    }, [map])

    useEffect(() => {
        if (drawLayer && map) {
            map.addLayer(drawLayer)
        }
    }, [drawLayer])

    useEffect(() => {
        if (map && draw) {
            if (addPoint) {
                map.addInteraction(draw)
                map.addInteraction(modify)
                drawLayer.getSource().clear()
                drawLayer.setVisible(true)
            } else {
                map.removeInteraction(draw)
                map.removeInteraction(modify)
                drawLayer.setVisible(false);
            }
        }
    },[map, addPoint])

    useEffect(() => {
        if (draw && modify && map) {
            draw.on('drawstart', (e) => {

                if (drawLayer.getSource().getFeatures().length > 0) {
                    drawLayer.getSource().clear()
                }
                getCoords(toLonLat(e.feature.getGeometry().getCoordinates()))
            })
            modify.on('modifyend', (e) => {
                getCoords(toLonLat(e.features.array_[0].getGeometry().getCoordinates()))
            })
            
        }
    }, [draw, modify, map])

    // useEffect(() => {
    //     if (map && addPoint) {
    //         map.on('dblclick', (e) => {
    //             e.preventDefault()
    //             startAdd();
    //         })
    //     }
    //     // Fix double click bug
    //     else if (map && !addPoint) {
    //         map.on('dblclick', () => {
    //             return null;
    //         })
    //     }
    // }, [map, addPoint])

    //Get location
    // const [geolocation, setGeolocation] = useState()
    // const locationRef = useRef()
    // locationRef.current = geolocation

    // useEffect(() => {
    //     const initialLocation = new Geolocation({
    //         // enableHighAccuracy must be set to true to have the heading value.
    //         trackingOptions: {
    //             enableHighAccuracy: true,
    //         },
    //         projection: 'EPSG:3857',
    //     });
    //     initialLocation.setTracking(true)
    //     setGeolocation(initialLocation)
    // }, [])

    //Initialize Map
    useEffect(() => {
        if (baseLayer && photoPointsLayer && buildingsLayer && plaquesLayer && institutionsLayer && coverageLayer && namesLayer) {
            const attribution = new Attribution();
            const initialMap = new Map({
                target: mapElement.current,
                layers: [
                    baseLayer,
                    namesLayer
                ],
                view: new View({
                    projection: 'EPSG:3857',
                    // center: geolocation.getPosition() ? geolocation.getPosition() : fromLonLat([-81.69806, 43.56257]),
                    center: fromLonLat([-81.69806, 43.56257]),
                    zoom: zoom,
                    maxZoom: 19,
                }),
                controls: defaultControls({ rotate: false, zoom: false, attribution: false }).extend([attribution]),
            })
            setMap(initialMap)
        }
    }, [baseLayer, photoPointsLayer, buildingsLayer, plaquesLayer, institutionsLayer, coverageLayer, namesLayer])

    useEffect(() => {
        if (map) {
            if (buildingsLayerToggle) {
                map.addLayer(buildingsLayer)
            }
            else {
                map.getLayers().forEach(layer => {
                    if (layer && layer.get('name') === 'buildings') {
                        map.removeLayer(layer);
                    }
                });
            }
        }
    }, [buildingsLayerToggle, map, buildingsLayer])

    useEffect(() => {
        if (map) {
            if (photosLayerToggle) {
                map.addLayer(photoPointsLayer)
            }
            else {
                map.getLayers().forEach(layer => {
                    if (layer && layer.get('name') === 'photoPoints') {
                        map.removeLayer(layer);
                    }
                });
            }
        }
    }, [photosLayerToggle, map, photoPointsLayer])

    useEffect(() => {
        if (map) {
            if (plaquesLayerToggle) {
                map.addLayer(plaquesLayer)
            }
            else {
                map.getLayers().forEach(layer => {
                    if (layer && layer.get('name') === 'plaques') {
                        map.removeLayer(layer);
                    }
                });
            }
        }
    }, [plaquesLayerToggle, map, plaquesLayer])

    useEffect(() => {
        if (map) {
            if (institutionsLayerToggle) {
                map.addLayer(institutionsLayer)
            }
            else {
                map.getLayers().forEach(layer => {
                    if (layer && layer.get('name') === 'institutions') {
                        map.removeLayer(layer);
                    }
                });
            }
        }
    }, [institutionsLayerToggle, map, institutionsLayer])

    useEffect(() => {
        if (map && coverageLayer) {
            if (addPoint) {
                map.addLayer(coverageLayer)
            }
            else {
                map.getLayers().forEach(layer => {
                    if (layer && layer.get('name') === 'coverage') {
                        map.removeLayer(layer);
                    }
                });
            }
        }
    }, [addPoint, map, coverageLayer])

    //Rasters
    let rasterLayer = new TileLayer()
    let source
    

    async function getMapLayers() {
        let layers = []
        map.getLayers().forEach(layer => {
            if (layer.get('name')) {
                layers.push(layer.get('name'))
            }
        });
        return layers
    }

    useEffect(() => {
        if (map) {
            getMapLayers()
                .then(layers => {
                    for (let x=0; x<activeRasters.length; x++) {
                        if (!layers.includes(`raster-${activeRasters[x]}`)) {
                            source = new XYZ({
                                url: `${domain}/raster/algebra/{z}/{x}/{y}.png?layers=r:0=${activeRasters[x]},g:1=${activeRasters[x]},b:2=${activeRasters[x]}&alpha=0`
                            })
                            rasterLayer.setSource(source)
                            rasterLayer.set('name', `raster-${activeRasters[x]}`)
                            map.addLayer(rasterLayer)
                        }
                    }
                    if (layers.length > 1) {
                        for (let y=0; y<layers.length; y++) {
                            if (layers[y].includes('raster')) {
                                if(!activeRasters.includes(parseInt(layers[y].split('-')[1]))) {
                                    map.getLayers().forEach(layer => {
                                        if (layer && layer.get('name') === layers[y]) {
                                            map.removeLayer(layer);
                                        }
                                    });
                                }
                            }
                        }
                    }
                    if (opacities.length > 0) {
                        map.getLayers().forEach(layer => {
                            if (layer.get('name')) {
                                if (layer.get('name').includes('raster-')) {
                                    let opacity_value = opacities.find(opacity => opacity.layer === layer.get('name')) ? opacities.find(opacity => opacity.layer === layer.get('name')).opacity : 1
                                    layer.setOpacity(opacity_value)
                                }
                            }
                        })
                    }
                })
        }
    }, [rasterTrigger, map])

    useEffect(() => {
        if (map && opacityChange) {
            let opacityArray = []
            map.getLayers().forEach(layer => {
                if (layer.get('name')) {
                    if (layer.get('name').includes('raster-')) {
                        if (layer && layer.get('name') === ('raster-' + opacityChange.id.toString())) {
                            layer.setOpacity(opacityChange.value)
                        }
                        opacityArray.push({layer: layer.get('name'), opacity: layer.getOpacity()})
                    }
                }
            })
            getOpacities(opacityArray)
        }
    }, [map, opacityChange])

    //Zoom to place
    useEffect(() => {
        if (map && place) {
            map.getView().setCenter(place)
            // if (place.type === "address") {
            //     map.getView().setZoom(19)
            // }
            map.getView().setZoom(16)
        }
    }, [map, place])

    //Zoom to bookmark
    useEffect(() => {
        if (bookmark && map) {
            getGeoJSON(`${domain}/api/bookmark/${bookmark}`)
                .then(bookInfo => {
                    if (bookInfo.geometry) {
                        map.getView().setCenter(bookInfo.geometry.coordinates)
                        map.getView().setZoom(parseInt(bookInfo.properties.zoom))
                    }
                })
        }
    }, [bookmark, map])

    return(
        <>
            <div ref={mapElement} class="mapDiv"></div>
            {!map ? 
                <div className="errorScreen">
                    <span>An error occurred loading the map. Please try again.</span>
                </div>
            : null}
        </>
    )
}

export default MainMap
