//Styling
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import "../App.css"

function PlaquePopup({plaque, getPlaque}) {
    const close = () => {
        getPlaque(null)
    }

    return(
        <>
            {plaque ?
                <Modal show={true} onHide={close} dialogClassName="modal-60w">
                    <Modal.Header closeButton>
                        <Modal.Title>{plaque.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{maxHeight: "60vh", overflowY: "scroll"}}>
                        {plaque.text}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={close}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
            : null}
        </>
    )
}

export default PlaquePopup