import {Navigate, useNavigate} from 'react-router-dom'
import { useContext } from "react"
import AuthContext from "../context/AuthContext"
import { useParams } from 'react-router-dom'

const PasswordReset = () => {
    let {uid, token} = useParams()
    let {logoutUser, domain} = useContext(AuthContext)
    const history = useNavigate()

    const resetPasswordConfirm = async (e) => {
        e.preventDefault()
        let response = await fetch(`${domain}/auth/users/reset_password_confirm/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'uid': uid,
                'token': token,
                'new_password': e.target.password.value,
                're_new_password': e.target.re_password.value
            })
        })
        if (response.status === 204) {
            alert("Your password has been reset.")
            logoutUser()
            history("/")
        }
        else {
            let data = await response.json()
            if (data.new_password) {
                alert(data.new_password[0])
            }
            else if (data.non_field_errors) {
                alert(data.non_field_errors[0])
            }
            else {
                alert("Something went wrong. Try again. If this issue persists, email digitalhistorycanada@gmail.com")
            }
        }
    }

    return(
        <div className="formPage">
            <form onSubmit={resetPasswordConfirm} style={{marginTop: "10vh"}}>
                <div class="mb-3">
                    <label for="password" class="form-label">New password</label>
                    <input id="password" class="form-control" type="password" name="password" placeholder="Password..." />
                </div>
                <div class="mb-3">
                    <label for="re_password" class="form-label">Retype new password</label>
                    <input id="re_password" class="form-control" type="password" name="re_password" placeholder="Retype password..." />
                </div>
                <input class="btn btn-primary" type="submit" value="Change password" />
            </form>
        </div>
    )
}

export default PasswordReset