//Styling
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "../App.css"

//Components
import ImageDisplay from './imageDisplay'

function BuildingPopup ({building, getBuilding, getPopupId}) {
    const closeBuilding = () => {
        getBuilding(null)
    }

    //Fix the date format
    function isoDate(date) {
        let isoDate = ''
        let dateParts
        let returnDate = ""
        let months = ["January","February","March","April","May","June","July","August","September","October","November","December"]
        if (date) {
            let dateString = date.toString()
            for (let i=0; i<4; i++) {
                isoDate += dateString[i]
            }     
            if (!(dateString[5] === '0' && dateString[4] === '0')) {
                isoDate += '-'
                isoDate += dateString[4]
                isoDate += dateString[5]
                if (!(dateString[7] === '0' && dateString[6] === '0')) {
                    isoDate += '-'
                    isoDate += dateString[6]
                    isoDate += dateString[7]
                }
            }
            if (!(dateString[5] === '0' && dateString[4] === '0' && dateString[7] === '0' && dateString[6] === '0')) {
                dateParts = isoDate.split("-")
                returnDate = months[parseInt(dateParts[1])-1] + " " + dateParts[2].replace(/^0+/, '') + ", " + dateParts[0]
            }
            else {
                returnDate = isoDate
            }
            return returnDate
        }
        else {
            return null
        }
    }

    return(
        <>
        {building ?
        <Modal show={true} onHide={closeBuilding} dialogClassName="modal-90w">
            <Modal.Header closeButton>
                <Modal.Title>{building.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container style={{maxHeight: "60vh", overflowY: "hidden", overflowX: "hidden"}}>
                    <Row style={{maxHeight: "60vh", overflowY: "scroll", overflowX: "hidden"}}>
                        <Col style={{maxHeight: "50vh", overflowY: "scroll", overflowX: "hidden"}} xs={12} md={8}>
                            <h4>Description</h4>
                            <Table striped bordered hover>
                                <tbody>
                                    {building.building_category ?
                                    <tr>
                                        <td>Type:</td>
                                        <td>{building.building_category.name}</td>
                                    </tr>
                                    : null}
                                    {building.architectural_style ?
                                    <tr>
                                        <td>Architectural Style:</td>
                                        <td>{building.architectural_style.name}</td>
                                    </tr>
                                    : null}
                                    <tr>
                                        <td>Date of Construction:</td>
                                        <td>{isoDate(building.date_of_construction)}</td>
                                    </tr>
                                    <tr>
                                        <td>Date of Demolition:</td>
                                        <td>{isoDate(building.date_of_demolition)}</td>
                                    </tr>
                                    {building.heritage_registry_entry ?
                                        <tr>
                                            <td>Heritage Register Entry:</td>
                                            <td><a href={building.heritage_registry_entry} target="_blank">Click Here</a></td>
                                        </tr>
                                    : null}
                                </tbody>
                            </Table>
                            <p><b>History:</b></p>
                            <span>{building.historical_description}</span>
                            <p><b>Architecture:</b></p>
                            <span>{building.architectural_description}</span>
                        </Col>
                        <Col style={{maxHeight: "50vh", overflowY: "scroll", overflowX: "hidden"}} xs={12} md={4}>
                            <h4>Items</h4>
                            {/* <div className="itemBox"> */}
                            {building.items.map(item => {
                                return(
                                    <ImageDisplay item={item} inPopup={false} getPopupId={getPopupId} />
                                )
                            })}
                            {/* </div> */}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
            <button type="button" class="btn btn-secondary" onClick={closeBuilding}>
                Close
            </button>
            </Modal.Footer>
        </Modal>
        : null}
        </>
    )
}

export default BuildingPopup;