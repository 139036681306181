//Context
import { useState, useCallback, useContext, useEffect } from 'react'
import AuthContext from '../context/AuthContext';

//Components
import ImageUpload from './imageUpload';
import BuildingSelector from './buildingSelector';

//Styling
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {fromLonLat} from "ol/proj";
import "../App.css"

function ItemUpload({add, finishAdd, coords}) {
    let {user, domain} = useContext(AuthContext)

    const [source, setSource] = useState(null)

    const onSourceChange = (e) => {
        setSource(e.target.value)
    }

    const [type, setType] = useState("Audio")

    const onTypeChange = (e) => {
        setType(e.target.value)
        if (e.target.value === "Video") {
            setSource("YouTube")
        }
    }

    const [link, setLink] = useState("")

    const onSourceLinkChange = (e) => {
        setLink(e.target.value)
    }

    const [videoLink, setVideoLink] = useState()

    const onVideoChange = (e) => {
        setVideoLink(e.target.value)
    }

    const [audioLink, setAudioLink] = useState()

    const onAudioChange = (e) => {
        setAudioLink(e.target.value)
    }

    const [description, setDescription] = useState("")

    const onDescriptionChange = (e) => {
        setDescription(e.target.value)
    }

    //Handle buildings
    const [itemBuildings, setItemBuildings] = useState([])

    const getItemBuildings = useCallback((buildings) => {
        setItemBuildings(buildings)
    })

    //Handle organizations
    const [organizations, setOrganizations] = useState([])

    const getOrganizations = async(coords) => {
        const res = await fetch(`${domain}/api/institutions/?point=${fromLonLat(coords)}`)
        const data = await res.json()
        if (data.features.length === 0 && user.staff === false) {
            alert("No organization has sponsored this area, so no items can be added here. Contact your local museum, archives, or historical society to sponsor this area.")
            finishAdd();
        }
        else {
            setOrganizations(data.features)
        }
    }

    const [selectedOrg, setSelectedOrg] = useState()

    const changeSelectedOrg = (e) => {
        setSelectedOrg(e.target.value)
    }

    useEffect(() => {
        if (coords) {
            getOrganizations(coords)
        }
    }, [coords])

    const postItem = () => {
        if (user) {
            if (user.staff === true || selectedOrg) {
                let coordinates = fromLonLat(coords)
                let omeka_link, omeka_id
                if (source === "Omeka") {
                    omeka_link = link.split("/")
                    omeka_id = omeka_link[omeka_link.length - 1]
                }
                let postData = {
                    location: `${coordinates[0]},${coordinates[1]}`,
                    omeka_id: omeka_id ? omeka_id : null,
                    source: source === "Local" ? "lha" : source === "External Link" ? "link" : source.toLowerCase(),
                    type: type === "Paper Record" ? "paper_record" : type.toLowerCase(),
                    buildings: [],
                    external_url: videoLink && type == 'Video' ? videoLink : audioLink && type == 'Audio' ? audioLink : link ? link : null,
                    description: description.length > 0 ? description : null,
                    buildings: itemBuildings.map(building => {return parseInt(building.id)}),
                    user: user ? user.user_id : null,
                    organization: selectedOrg ? selectedOrg : null,
                    published: user.staff ? true : false
                }
                fetch(`${domain}/api/items/`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'JWT ' + JSON.parse(localStorage.getItem('authTokens')).access
                    },
                    method: "POST",
                    body: JSON.stringify(postData)
                }).then(res => res.json()).then(data => {
                    if (images.length > 0 && data.properties.id) {
                        Promise.all(images.map(image => {
                            image.append("item", parseInt(data.properties.id))
                            // image.append("item", parseInt(data.id))
                            // for(var pair of image.entries()) {
                            //     console.log(pair[0]+ ', '+ pair[1]);
                            // }
                            fetch(`${domain}/api/scans/create/`, {
                                headers: {
                                    'Authorization': 'JWT ' + JSON.parse(localStorage.getItem('authTokens')).access
                                },
                                method: "POST",
                                body: image
                            }).then(res => {
                                res.json()
                            }).then(data => {
                                console.log(data)
                            }).catch(err => {
                                console.log(err)
                            })
                        })).then(() => {
                            // console.log(data)
                            alert("Your item and images were successfully added to our records. Once one of our cataloguers reviews the item, it will be displayed on our website.")
                            finishAdd();
                        })
                    }
                    else if (images.length === 0 && data.properties.id) {
                        alert("Your item was successfully added to our records. Once one of our cataloguers reviews the item, it will be displayed on our website.")
                        finishAdd();
                    }
                    // else if (res.status === 401) {
                    //     alert("Authentication error! Make sure you are logged in and try again.")
                    //     finishAdd();
                    // }
                    else {
                        alert("An error occurred. Please try again. If this error persists, please contact us at digitalhistorycanada@gmail.com")
                        finishAdd();
                    }
                }).catch(err => {
                    console.log(err)
                })
            } else {
                alert("Choose an organization before uploading your item!")
            }
        }
        else {
            alert("You are not logged in! Log in to add an item.")
        }
    }

    const [images, setImages] = useState([])
    const getImages = useCallback((images) => {
        setImages(images)
    })

    return(
        <Modal show={add} onHide={finishAdd} dialogClassName="modal-90w">
            <Modal.Header closeButton>
                <Modal.Title>Add Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="itemUpload">
                            {/* <label for="email" class="form-label">Email address</label>
                            <input id="email" class="form-control" type="email" name="email" placeholder="Email" /> */}
                    <div style={{"padding": 2}}>
                        <label style={{"paddingRight": 2}} for="organization" class="form-label">Organization: </label>
                        <select id="organzation" name="organization" value={selectedOrg} onChange={changeSelectedOrg} class="form-select">
                            <option value=""></option>
                            {organizations.map(organization => {
                                // console.log(organization)
                                if (user.staff === true || organization.properties.virtual_accessions === true) {
                                    return(
                                        <option value={organization.properties.id}>{organization.properties.name}</option>
                                    )
                                }
                            })}
                        </select>
                    </div>
                    <div style={{"padding": 2}}>
                        <label style={{"paddingRight": 2}} for="type" class="form-label">Type: </label>
                        <select id="type" name="type" value={type} onChange={onTypeChange} class="form-select">
                            <option>Audio</option>
                            <option>Object</option>
                            {/* <option>Painting</option> */}
                            <option>Paper Record</option>
                            <option>Photograph</option>
                            <option>Postcard</option>
                            <option>Video</option>
                        </select>
                    </div>
                    <div style={{"padding": 2}}>
                        <label style={{"paddingRight": 2}} for="source" class="form-label">Source: </label>
                        {type === 'Video' ?
                            <select id="source" name="source" value={source} onChange={onSourceChange} class="form-select">
                                <option></option>
                                <option>YouTube</option>
                                <option>Other Video</option>
                            </select>
                        : type === 'Audio' ?
                            <select id="source" name="source" value={source} onChange={onSourceChange} class="form-select">
                                <option></option>
                                <option>Apple Podcasts</option>
                                <option>Other Audio</option>
                            </select>
                        :
                            <select id="source" name="source" value={source} onChange={onSourceChange} class="form-select">
                                <option></option>
                                {/* <option>Access to Memory</option> */}
                                <option>Local</option>
                                <option>Omeka</option>
                                <option>External Link</option>
                            </select>
                        }
                    </div>
                    {source === "Omeka" || source === "Access to Memory" || source === "External Link" ?
                        <div style={{"padding": 2}}>
                            <label style={{"paddingRight": 2}} for="source-link" class="form-label">URL: </label>
                            <input type="text" id="source-link" name="source-link" value={link} onChange={onSourceLinkChange} class="form-control" />
                        </div>
                        : null
                    }
                    {source === "Local" && type !== "Video" && type !== "Audio" ?
                        <ImageUpload getImages={getImages} />
                    : type === "Video" ? 
                        <div style={{"padding": 2}}>
                            <label style={{"paddingRight": 2}} for="video-link" class="form-label">Video URL: </label>
                            <input type="text" id="video-link" name="video-link" value={videoLink} onChange={onVideoChange} class="form-control" />
                        </div>
                    : type === "Audio" ?
                        <div style={{"padding": 2}}>
                            <label style={{"paddingRight": 2}} for="audio-link" class="form-label">Audio URL: </label>
                            <input type="text" id="audio-link" name="audio-link" value={audioLink} onChange={onAudioChange} class="form-control" />
                        </div>
                    : null}
                    <BuildingSelector itemBuildings={itemBuildings} getItemBuildings={getItemBuildings} />
                    {source !== "Omeka" && source !== "Access to Memory" ?
                        <div style={{"padding": 2, display: "flex", flexDirection: "column"}}>
                            <label style={{"paddingRight": 2}} for="description" class="form-label">Description: </label>
                            <textarea id="description" rows={6} style={{width: '100%'}} value={description} onChange={onDescriptionChange} class="form-control" />
                        </div>
                        : null
                    }
                    <div style={{"padding": 2}}>
                        <span style={{"paddingRight": 2}}>Location: </span>
                        <span>{coords[1].toPrecision(7) + ", " + coords[0].toPrecision(7)}</span>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={finishAdd}>
                Close
            </Button>
            <Button variant="primary" onClick={postItem}>
                Save Changes
            </Button>
            </Modal.Footer>
      </Modal>
    )   
}

export default ItemUpload