import { useState, useEffect, useContext } from 'react'
import AuthContext from '../context/AuthContext'

import { Link } from 'react-router-dom'

import {Nav, Navbar, NavDropdown, Container} from 'react-bootstrap'
import dhCanadaLogo from "../icons/LHA_header_logo.png"
import "../App.css"

import UserIcon from './userIcon'

function Header() {
    let {user, toggleLogin, domain} = useContext(AuthContext)

    const [profile, setProfile] = useState()

    useEffect(() => {
        if (user) {
            fetch(`${domain}/api/user/profile/?id=${user.user_id}`)
                .then(res => res.json())
                .then(data => {
                    setProfile(data[0].profile)
                })
        }
    }, [user])

    return (
        <div className="header">
            <div className="leftHeader">
                <Link to="/" className="leftLink">Home</Link>
                <a className="leftLink" href="https://www.digitalhistory.ca/local-history-atlas/" target="_blank">Pricing & Info</a>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', zIndex: 999}}>
                <Link to="/"><img src={dhCanadaLogo} className="logo" /></Link>
            </div>
            <div className="rightHeader">
                {user ?
                    <div style={{display: "flex", height: "100%", justifyContent: "flex-end"}}>
                        <UserIcon profile={profile} name={user.name} menu={true} />
                        {/* <p style={{cursor: "pointer"}} onClick={logoutUser}>Logout</p> */}
                    </div>
                    : <button className="btn btn-primary loginButton" onClick={toggleLogin}>Login</button>}
            </div>
        </div>
    )
}

export default Header